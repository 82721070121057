import React from 'react';
import { keyframes } from '@emotion/react';
import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';
import { CaretDownIcon } from '@radix-ui/react-icons';
import type {
    NavigationMenuContentProps, NavigationMenuItemProps,
    NavigationMenuLinkProps, NavigationMenuListProps, NavigationMenuSubProps, NavigationMenuTriggerProps,
    NavigationMenuViewportImplProps,
} from '@radix-ui/react-navigation-menu';
import { colors } from '../colors';
import { borderRadius } from '../tokens';

const enterFromRight = keyframes({
    from: { transform: 'translateX(200px)', opacity: 0 },
    to: { transform: 'translateX(0)', opacity: 1 },
});

const enterFromLeft = keyframes({
    from: { transform: 'translateX(-200px)', opacity: 0 },
    to: { transform: 'translateX(0)', opacity: 1 },
});

const exitToRight = keyframes({
    from: { transform: 'translateX(0)', opacity: 1 },
    to: { transform: 'translateX(200px)', opacity: 0 },
});

const exitToLeft = keyframes({
    from: { transform: 'translateX(0)', opacity: 1 },
    to: { transform: 'translateX(-200px)', opacity: 0 },
});

const fadeIn = keyframes({
    from: { opacity: 0 },
    to: { opacity: 1 },
});

const fadeOut = keyframes({
    from: { opacity: 1 },
    to: { opacity: 0 },
});

const itemStyles = {
    outline: 'none',
    userSelect: 'none',
};

/*
This is the drop down icon
*/

export const DropdownTriggerIndicator = ({ className, style }: HtmlPropsNoRef<{ className?: string }>): JSX.Element => (
    <CaretDownIcon
        aria-hidden
        className={className}
        css={{
            position: 'relative',
            color: 'black',
            top: 1,
            transition: 'transform 250ms ease',
            '[data-state=open] &': { transform: 'rotate(-180deg)' },

        }}
        style={style}
    />
);

/*
Megamenu indicator controls the styling of the mega menu content
*/

export const MegaMenuIndicator = ({ className, ...props }: HtmlPropsNoRef<HTMLDivElement>): JSX.Element => (
    <NavigationMenuPrimitive.Indicator
        className={className}
        css={{
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
            height: '1px',
            top: '100%',
            zIndex: 1,
            transition: 'width, transform 250ms ease',
            '&[data-state="visible"]': { animation: `${fadeIn} 200ms ease` },
            '&[data-state="hidden"]': { transition: 'none', animation: `${fadeOut} 200ms ease` },
            /* '&:after': {
                position: 'absolute',
                content: '""',
                flex: 'none',
                width: '12px',
                height: '12px',
                backgroundColor: '#fff',
                borderRadius: '2px',
                transform: 'rotate(45deg)',
                bottom: '-16px',
                boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
            } */
        }}
        {...props}
    >
        <div
            css={{
                position: 'relative',
                top: '-20px',
                backgroundColor: 'currentColor',
                width: '100%',
                height: '1px',
            }}
        />
    </NavigationMenuPrimitive.Indicator>
);

/*
These triggers open up the submenu
*/
export const DropdownTrigger = ({ className, ...props }: CustomProps<NavigationMenuTriggerProps>): JSX.Element => (
    <NavigationMenuPrimitive.Trigger
        className={className}
        css={{
            ...itemStyles,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
        }}
        {...props}
    />
);

/*
This is the root of the mega menu
*/

export const MegaMenu = React.forwardRef<
HTMLDivElement, CustomProps<NavigationMenuPrimitive.NavigationMenuProps
>>(({ className, ...props }, ref) => (
    <NavigationMenuPrimitive.Root
        ref={ref}
        className={className}
        css={{
            position: 'relative',
            width: '100%',
            zIndex: 1,
        }}
        {...props}
    />
));

MegaMenu.displayName = 'MegaMenu';

/*
 Subroot of the mega menu that houses the menu components
*/

export const MegaMenuDropdown = React.forwardRef<HTMLDivElement, CustomProps<Omit<NavigationMenuViewportImplProps, 'activeContentValue'>>>(({ className, children, ...props }, ref) => (
    <>
        {children}
        {/* <div
            css={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                top: '100%',
                left: 0,
                perspective: '2000px',
            }}
        >
            <NavigationMenuPrimitive.Viewport
                ref={ref}
                className={className}
                css={{
                    position: 'relative',
                    transformOrigin: 'top center',
                    marginTop: 10,
                    width: 'var(--radix-navigation-menu-viewport-width)',
                    backgroundColor: 'white',
                    borderRadius: 6,
                    overflow: 'hidden',
                    boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
                    height: 'var(--radix-navigation-menu-viewport-height)',
                    transition: 'width, height, 300ms ease',
                    '&[data-state="open"]': { animation: `${fadeIn} 200ms ease` },
                    '&[data-state="closed"]': { animation: `${fadeOut} 200ms ease` },
                }}
                {...props}
            />
        </div> */}
    </>
));

MegaMenuDropdown.displayName = 'MegaMenuDropdown';

/* this component is used for menu items that have no links */

export const MenuItem = ({ className, ...props }: CustomProps<NavigationMenuItemProps>): JSX.Element => (
    <NavigationMenuPrimitive.Item className={className} {...props} />
);

/* This is also a subroot. Comes after mega menu dropdown. */

export const MenuList = ({ className, ...props }: CustomProps<NavigationMenuListProps>): JSX.Element => (
    <NavigationMenuPrimitive.List
        className={className}
        css={{
            width: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            listStyle: 'none',
            height: '100%',
            // position: 'relative',
        }}
        {...props}
    />
);

/* This component acts as the root for the submenu */

export const DropdownInsert = ({ className, ...props }: CustomProps<NavigationMenuContentProps>): JSX.Element => (
    <NavigationMenuPrimitive.Content
        className={className}
        css={{
            position: 'absolute',
            // width: '100%',
            // height: '100%',
            '&[data-motion="from-start"]': { animationName: enterFromLeft },
            '&[data-motion="from-end"]': { animationName: enterFromRight },
            '&[data-motion="to-start"]': { animationName: exitToLeft },
            '&[data-motion="to-end"]': { animationName: exitToRight },
            animationDuration: '250ms',
            animationTimingFunction: 'ease',
            transition: 'width, height, 300ms ease',
            '&[data-state="open"]': { animation: `${fadeIn} 400ms ease` },
            '&[data-state="closed"]': { animation: `${fadeOut} 400ms ease` },
            marginTop: '60px',
            paddingTop: '20px',
        }}
    >
        <div
            css={{
                backgroundColor: colors.shadesWhite,
                borderRadius: borderRadius.medium,
                boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
            }}
            {...props}
        />
    </NavigationMenuPrimitive.Content>
);

/* This component houses the submenu and all its components */

export const SubMenu = ({ className, ...props }: CustomProps<NavigationMenuSubProps>): JSX.Element => (
    <NavigationMenuPrimitive.Sub
        className={className}
        css={{
            width: '100%',
            margin: 0,
            listStyle: 'none',
        }}
        {...props}
    />
);
